<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-4">
      <template v-if="loading">
        ???
      </template>
      <template v-else>
        {{ signal.name }}
      </template>
    </h3>
    <template v-if="loading">
      <div class="rounded-xl" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div>
        <img :src="signal.image" class="max-w-full rounded-xl">
      </div>

      <div v-if="signal.exchanges && signal.exchanges.length > 0" class="rounded-xl px-3 pt-2 pb-3 mt-3" :class="[$theme.cardBg]">
        <div class="text-sm opacity-60">{{ $t('on_exchanges') }}</div>
        <div class="flex mt-2">
          <div v-for="(exchange, exchangeIndex) in signal.exchanges" :key="exchangeIndex">
            <TheTooltip :id="`${exchange}_${exchangeIndex}`" :message="capitalizeFirstLetter(exchange)">
              <div class="flex items-center mr-2">
                <img :src="`/img/${exchange}.png`" alt="" class="w-5 rounded-full mr-1.5" />
                {{ capitalizeFirstLetter(exchange) }}
              </div>
            </TheTooltip>
          </div>
        </div>
      </div>

      <div v-if="signal.signal_description" class="rounded-xl px-3 pt-2 pb-3 mt-3" :class="[$theme.cardBg]">
        <div class="text-sm opacity-60">{{ $t('desc') }}</div>
        <RenderHtml :content="signal.signal_description.description" />
      </div>

      <div class="rounded-xl px-3 mt-3" :class="[$theme.cardBg]">
        <div v-if="signal.signal_description" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">Period</div>
          <div>{{ signal.signal_description.analysis_period }}</div>
        </div>
        <div class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('level_risk') }}</div>
          <div>
            <div class="flex items-center">
              <template v-if="signal.level_risk === 'low'">
                <TheTooltip :id="`${signal.id}_${signal.max_leverage}_lowrisk`" :message="$t('low_risk_desc')">
                            <span class="text-green-custom flex items-center">
                              <BoltIcon class="w-3 mr-1" /> {{ $t('low_risk') }}
                            </span>
                </TheTooltip>
              </template>
              <template v-if="signal.level_risk === 'medium'">
                <TheTooltip :id="`${signal.id}_${signal.max_leverage}_mediumrisk`" :message="$t('medium_risk_desc')">
                            <span class="text-yellow-500 flex items-center">
                              <BoltIcon class="w-3 mr-1" /> {{ $t('medium_risk') }}
                            </span>
                </TheTooltip>
              </template>
              <template v-if="signal.level_risk === 'high'">
                <TheTooltip :id="`${signal.id}_${signal.max_leverage}_highrisk`" :message="$t('high_risk_desc')">
                            <span class="text-red-600 flex items-center">
                              <BoltIcon class="w-3 mr-1" /> {{ $t('high_risk') }}
                            </span>
                </TheTooltip>
              </template>
              <div class="ml-2">
                <template v-if="signal.level_risk === 'low'">
                  <TheTooltip :id="`${signal.id}_${signal.level_risk}`" :message="$t('max_leverage')">
                <span class="text-green-custom">
                  x{{ signal.max_leverage }}
                </span>
                  </TheTooltip>
                </template>
                <template v-if="signal.level_risk === 'medium'">
                  <TheTooltip :id="`${signal.id}_${signal.level_risk}`" :message="$t('max_leverage')">
                    <span class="text-yellow-500">x{{ signal.max_leverage }}</span>
                  </TheTooltip>
                </template>
                <template v-if="signal.level_risk === 'high'">
                  <TheTooltip :id="`${signal.id}_${signal.level_risk}`" :message="$t('max_leverage')">
                <span class="text-red-600">
                  x{{ signal.max_leverage }}
                </span>
                  </TheTooltip>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-if="signal.account_deposit" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('account_deposit') }}</div>
          <div>{{ signal.account_deposit }}</div>
        </div>
        <div v-if="signal.max_drawdown_sum" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('max_drawdown_sum') }}</div>
          <div>{{ signal.max_drawdown_sum }}</div>
        </div>
        <div v-if="signal.max_drawdown_percent" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('max_drawdown_percent') }}</div>
          <div>{{ signal.max_drawdown_percent }}%</div>
        </div>
        <div v-if="signal.net_profit_sum" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('net_profit_sum') }}</div>
          <div>{{ signal.net_profit_sum }}</div>
        </div>
        <div v-if="signal.net_profit_percent" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('net_profit_percent') }}</div>
          <div>{{ signal.net_profit_percent }}%</div>
        </div>
        <div v-if="signal.total_closed_positions" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('closed_positions') }}</div>
          <div>{{ signal.total_closed_positions }}</div>
        </div>
        <div v-if="signal.profit_positions_percent" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('profit_positions_percent') }}</div>
          <div>{{ signal.profit_positions_percent }}%</div>
        </div>
        <div v-if="signal.profit_factor" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('profit_factor') }}</div>
          <div>{{ signal.profit_factor }}</div>
        </div>
        <div v-if="signal.average_monthly_profit" class="grid grid-cols-2 gap-1 text-sm border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
          <div class="opacity-60">{{ $t('average_monthly_profit') }}</div>
          <div>{{ signal.average_monthly_profit }}</div>
        </div>
        <div v-if="signal.average_profit_per_trade" class="grid grid-cols-2 gap-1 text-sm pt-2 pb-2">
          <div class="opacity-60">{{ $t('average_profit_trade') }}</div>
          <div>{{ signal.average_profit_per_trade }}</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { addBackButton, hideBackButton } from "@/helpers/telegramHelper";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import LoadingBlock from "@/components/LoadingBlock.vue";
import RenderHtml from "@/components/elem/RenderHtml.vue";
import TheTooltip from "@/components/elem/TheTooltip.vue";
import {BoltIcon} from "@heroicons/vue/16/solid";

export default {
  components: {
    BoltIcon,
    TheTooltip,
    LoadingBlock,
    RenderHtml
  },
  methods: {
    onLoadSignal() {
      const id = this.$route.params.id;

      this.$api.get(`/signals/${id}`).then(result => {
        console.log('result', result.data.data);
        this.signal = result.data.data;
        this.loading = false;
      })
    }
  },

  data() {
    return {
      loading: true,
      signal: null,
      capitalizeFirstLetter
    }
  },

  mounted() {
    this.onLoadSignal();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>