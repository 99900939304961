<template>
  <div class="pt-4 px-4 pb-20">
    <h1 class="text-3xl font-bold mb-5">
      {{ $t('positions') }}
    </h1>

    <div class="mb-3">
      <div class="">
        <nav class="flex space-x-4" aria-label="Tabs">
          <a
              v-for="tab in tabs"
              :key="tab.key"
              href="javascript:void(0)"
              @click="onChangeTab(tab)"
              :class="[tab.key === this.activeTab ? `${$theme.activeTabBg} ${$theme.activeTabTextColor}` : $theme.tabTextColor, 'rounded-lg px-3 py-2 text-sm font-medium']"
          >
            {{ tab.name }}
            <template v-if="tab.key === 'open'">({{ orders.length }})</template>
          </a>
        </nav>
      </div>
    </div>

    <template v-if="activeTab === 'open'">
      <template v-if="loading">
        <div class="mb-3">
          <OrderItemPlaceholder />
        </div>
        <div class="mb-3">
          <OrderItemPlaceholder />
        </div>
      </template>
      <template v-else>
        <template v-if="orders.length">
          <div v-for="order in orders" :key="order.id" class="mb-3">
            <OrderItem :order="order" :is_edit="true" @close-position="onClosedPosition" />
          </div>
        </template>
        <template v-else>
          <div class="rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
            <p class="text-center">{{ $t('no_active_positions') }}</p>
          </div>
        </template>
      </template>
    </template>

    <template v-if="activeTab === 'history'">
      <template v-if="loadingHistory">
        <div class="mb-3">
          <OrderItemPlaceholder />
        </div>
        <div class="mb-3">
          <OrderItemPlaceholder />
        </div>
      </template>
      <template v-else>
        <template v-if="history.data.length">
          <div v-for="order in history.data" :key="order.id" class="mb-3">
            <OrderItem :order="order" />
          </div>
        </template>
        <template v-else>
          <div class="rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
            <p class="text-center">{{ $t('no_history') }}</p>
          </div>
        </template>

        <template v-if="history.meta.last_page > this.historyParams.page">
          <button
              @click="onLoadHistoryMore"
              class="w-full text-sm py-3 rounded-xl font-medium"
              :class="[
                  loadingHistoryMore ? 'opacity-60 pointer-events-none' : '',
                  $theme.outlineBtnBg,
                  $theme.outlineBtnTextColor,
              ]"
          >
            <template v-if="loadingHistoryMore">
              <ButtonSpinnerBlue />
            </template>
            {{ $t('load_more') }}
          </button>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import OrderItem from "@/components/elem/OrderItem.vue";
import OrderItemPlaceholder from "@/components/placeholders/OrderItemPlaceholder.vue";
import ButtonSpinnerBlue from "@/components/ButtonSpinnerBlue.vue";

export default {
  components: {
    OrderItem,
    ButtonSpinnerBlue,
    OrderItemPlaceholder
  },

  methods: {
    onChangeTab(tab) {
      this.activeTab = tab.key;

      if (this.activeTab === 'open') {
        this.loadOpenOrders();
      }

      if (this.activeTab === 'history') {
        this.loadHistoryOrder();
      }
    },

    onClosedPosition() {
      this.loadOpenOrders();
    },

    loadOpenOrders() {
      console.log('Load orders...');

      this.$api.get('/orders/open').then((result) => {
        this.orders = result.data.data;
        this.loading = false
      }).catch(e => {
        console.log(e)
        this.error = e
      })
    },

    loadHistoryOrder() {
      console.log('Load history orders...');

      this.loadingHistory = true;

      this.$api.get('/orders', {
        params: this.historyParams
      }).then((result) => {
        console.log(result.data);
        this.history = result.data;
        this.loadingHistory = false;
      }).catch(e => {
        console.log(e)
        this.error = e
      })
    },

    onLoadHistoryMore() {
      this.loadingHistoryMore = true;
      this.historyParams.page += 1;

      this.$api.get('/orders', {
        params: this.historyParams
      }).then((result) => {
        this.history.data = [...this.history.data, ...result.data.data];
        this.loadingHistoryMore = false;
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },

    onInitBinanceWebsocket() {
      this.$api.post('/binance/listen-key', {
        exchange_id: 17,
        type: this.typeListenKey,
        key: this.listenKey,
      }).then((result) => {
        console.log(result);

        this.listenKey = result.data.listenKey;

        const ws = new WebSocket(`wss://fstream.binance.com/ws/${this.listenKey}`);

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log(data);
        };

        ws.onclose = () => {
          console.log('WebSocket соединение закрыто');
        };
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    }
  },

  data() {
    return {
      loading: true,
      loadingHistory: true,
      loadingHistoryMore: false,
      orders: [],
      history: null,
      error: null,
      intervalId: null,
      activeTab: 'open',
      tabs: [
        { name: this.$t('open'), key: 'open' },
        { name: this.$t('history'), key: 'history' },
      ],
      historyParams: {
        user_id: this.$user.id,
        per_page: 10,
        page: 1
      },

      typeListenKey: 'create',
      listenKey: '',
    }
  },

  mounted() {
    this.loadOpenOrders()
    this.intervalId = setInterval(this.loadOpenOrders, 5000);
  },

  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
}
</script>
