<template>
  <div class="fixed z-20 w-full bottom-0 left-0 border-t px-2 py-2" :class="[
      $theme.navBg,
      $theme.cardLabelTextColor,
      $theme.navBorderColor,
  ]">
    <nav class="grid text-sm grid-cols-5 gap-1">
      <router-link
          to="/"
          class="relative flex flex-col items-center rounded-lg py-1"
          :class="[
            $route.name === 'home' ? `${$theme.navActiveBg} ${$theme.navActiveTextColor}` : ''
          ]"
      >
        <HomeIcon class="w-5" />
        <span>{{ $t('home') }}</span>
      </router-link>
      <router-link
          to="/orders"
          class="flex flex-col items-center rounded-lg py-1"
          :class="[
            $route.name === 'orders' ? `${$theme.navActiveBg} ${$theme.navActiveTextColor}` : ''
          ]"
      >
        <ArrowTrendingUpIcon class="w-5" />
        <span>{{ $t('orders') }}</span>
      </router-link>
      <router-link
          to="/stats"
          class="flex flex-col items-center rounded-lg py-1"
          :class="[
            $route.name === 'stats' ? `${$theme.navActiveBg} ${$theme.navActiveTextColor}` : ''
          ]"
      >
        <ChartPieIcon class="w-5" />
        <span>{{ $t('stats') }}</span>
      </router-link>
      <router-link
          to="/refs"
          class="flex flex-col items-center rounded-lg py-1"
          :class="[$route.name === 'refs' ? `${$theme.navActiveBg} ${$theme.navActiveTextColor}` : '']"
      >
        <UsersIcon class="w-5" />
        <span>{{ $t('refs') }}</span>
      </router-link>

      <Popover class="relative">
        <PopoverButton
          ref="popoverButton"
          class="w-full flex flex-col items-center rounded-lg py-1 focus:outline-none"
        >
          <EllipsisHorizontalCircleIcon class="w-5" />
          <span>{{ $t('more') }}</span>
        </PopoverButton>

        <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1/2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1/2"
        >
          <PopoverPanel
              class="absolute bottom-full right-0 z-10 mb-5 flex w-screen max-w-max pl-4"
          >
            <div class="w-screen max-w-sm flex-auto rounded-xl text-sm leading-6 shadow-lg ring-1 ring-gray-900/5" :class="[$theme.navBg]">
              <div class="grid grid-cols-2 gap-1 p-3">
                <template v-if="$settings.screener">
                  <router-link to="/setting" @click="closePopover" class="pointer-events-none opacity-80 group relative flex items-center gap-3 rounded-lg p-2 hover:bg-gray-50">
                    <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg" :class="[$theme.lightButtonBg]">
                      <ArrowTrendingUpIcon class="h-6 w-6" :class="[$theme.navActiveTextColor2]" />
                    </div>
                    <div>
                      <div class="font-semibold text-sm" :class="[$theme.baseTextColor]">
                        {{ $t('screener') }} <span class="text-xs text-white bg-red-500 px-1 rounded-xl">{{ $t('soon') }}...</span>
                        <span class="absolute inset-0" />
                      </div>
                    </div>
                  </router-link>
                </template>
                <router-link to="/tariff" @click="closePopover" class="group relative flex items-center gap-3 rounded-lg p-2">
                  <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg" :class="[$theme.lightButtonBg]">
                    <CurrencyDollarIcon class="h-6 w-6" :class="[$theme.navActiveTextColor2]" />
                  </div>
                  <div>
                    <div class="font-semibold text-sm" :class="[$theme.baseTextColor]">
                      {{ $t('tariff') }}
                      <span class="absolute inset-0" />
                    </div>
                  </div>
                </router-link>
                <router-link to="/setting" @click="closePopover" class="group relative flex items-center gap-3 rounded-lg p-2">
                  <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg" :class="[$theme.lightButtonBg]">
                    <Cog6ToothIcon class="h-6 w-6" :class="[$theme.navActiveTextColor2]" />
                  </div>
                  <div>
                    <div class="font-semibold text-sm" :class="[$theme.baseTextColor]">
                      {{ $t('settings') }}
                      <span class="absolute inset-0" />
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </nav>
  </div>
</template>
<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import {
  ArrowTrendingUpIcon,
  CurrencyDollarIcon,
  ChartPieIcon,
  UsersIcon,
  HomeIcon,
  EllipsisHorizontalCircleIcon,
  Cog6ToothIcon,
} from '@heroicons/vue/24/outline'

export default {
  components: {
    ArrowTrendingUpIcon,
    CurrencyDollarIcon,
    ChartPieIcon,
    UsersIcon,
    HomeIcon,
    EllipsisHorizontalCircleIcon,
    Cog6ToothIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
  },

  computed: {
    appStatus() {
      return this.$user.app_status;
    },

    tariffInfo() {
      return this.$user.tariff;
    },
  },

  methods: {
    closePopover() {
      this.$refs.popoverButton.$el.click();
    }
  },

  mounted() {
  },
}
</script>