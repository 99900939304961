<template>
  <div class="ui-order-item rounded-lg" :class="[$theme.cardBg]">
    <div class="flex px-2 py-2.5 border-b" :class="[$theme.cardBorderColor]">
      <div class="w-full flex items-center justify-between font-semibold whitespace-nowrap">
        <div class="flex items-center">
          <div>
            <div class="relative flex items-center">
              <div class="h-6 w-24 rounded-xl animate-pulse" :class="[$theme.placeBg]"></div>
            </div>
          </div>
          <div class="pl-3">
            <div class="text-white text-sm font-normal px-2 rounded-lg h-5 w-16 rounded-xl animate-pulse" :class="[$theme.placeBg]"></div>
          </div>
        </div>
        <div class="flex items-center font-normal text-sm">
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 px-2 py-2">
      <div class="whitespace-nowrap animate-pulse">
        <div class="text-xs opacity-20">
          {{ $t('in_position') }}
        </div>
        <div class="text-sm opacity-20">
          N/A
        </div>
      </div>
      <div class="whitespace-nowrap animate-pulse">
        <div class="text-xs opacity-20">{{ $t('size') }}</div>
        <div class="text-sm mt-0.5 opacity-20">
          <span>N/A</span>
        </div>
      </div>
      <div class="whitespace-nowrap animate-pulse">
        <div class="text-xs opacity-20">P&L</div>
        <div :class="[
            'text-sm mt-0.5 opacity-20'
          ]">N/A</div>
      </div>
    </div>
    <div class="w-full px-2 py-1.5 text-sm border-t animate-pulse" :class="[$theme.cardBorderColor]">
      <div class="flex items-center justify-between py-0.5">
        <div class="h-3.5 w-12 rounded-xl" :class="[$theme.placeBg]"></div>
        <div class="h-3.5 w-12 rounded-xl" :class="[$theme.placeBg]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
}
</script>

<style>
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}
</style>
