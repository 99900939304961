<template>
  <div>
    <div class="rounded-xl px-4 pt-3 pb-4" :class="[$theme.cardBg]">
      <h3 class="text-2xl font-bold mb-5">
        {{ $t('add_exchange_account') }}
      </h3>
      <template v-if="loading">
        <LoadingBlock />
      </template>
      <template v-else>
        <div>
          <Listbox as="div" v-model="selectedExchange">
            <ListboxLabel class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
              {{ $t('exchange') }}
            </ListboxLabel>
            <div class="relative mt-2">
              <ListboxButton
                class="relative w-full cursor-default rounded-lg py-2 pl-3.5 pr-10 text-left shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2"
                :class="[
                  $theme.cardLabelTextColor,
                  $theme.inputPlaceTextColor,
                  $theme.inputRingFocusColor,
                  $theme.inputBorderColor,
                  $theme.inputRingColor,
                  $theme.cardBg,
                ]"
              >
              <span class="flex items-center">
                <template v-if="selectedExchange">
                  <img :src="`/img/${selectedExchange.slug}.png`" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                  <span class="ml-3 block truncate">{{ selectedExchange.name }}</span>
                </template>
                <template v-else>
                  <span class="ml-3 block truncate">{{ $t('select_exchange') }}</span>
                </template>
              </span>
                <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5" :class="[$theme.cardLabelTextColor]" aria-hidden="true" />
              </span>
              </ListboxButton>

              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <ListboxOption as="template" v-for="exchange in exchanges" :key="exchange.id" :value="exchange" v-slot="{ active }">
                    <li :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                      <div class="flex items-center">
                        <img :src="`/img/${exchange.slug}.png`" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                        <span :class="[active ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                        {{ exchange.name }}
                      </span>
                      </div>
                      <span v-if="selectedExchange && selectedExchange.slug === exchange.slug" :class="[$theme.navActiveTextColor2, 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
          <div class="mt-4">
            <div class="relative">
              <div class="rounded-lg p-2 mb-2 relative" :class="[$theme.infoAlertBg]">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon class="h-5 w-5" :class="[$theme.infoAlertIconColor]" aria-hidden="true" />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm" :class="[$theme.infoAlertTextColor]">{{ $t('add_ip_addresses') }}</p>
                  </div>
                </div>
              </div>
              <div class="relative">
                <input
                    readonly
                    v-model="allowedIPs"
                    type="text"
                    id="ip_address"
                    ref="ipAddressInput"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
                <button class="copy-button absolute right-2 top-1" :class="[$theme.navActiveTextColor2]" @click="copyToClipboard">
                  {{ $t('copy') }}
                </button>
              </div>
            </div>
            <div class="mt-4">
              <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Key</label>
              <div class="mt-2">
                <input
                    v-model="form.api_key"
                    type="text"
                    id="api_key"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Secret</label>
              <div class="mt-2">
                <input
                    v-model="form.api_secret"
                    type="text"
                    id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                {{ $t('api_passphrase') }}
              </label>
              <div class="mt-2">
                <input
                    v-model="form.api_passphrase"
                    type="text" id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
          </div>
          <template v-if="formError.errors">
            <div class="rounded-md bg-red-50 p-4 mt-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">{{ formError.message }}</h3>
                  <div class="mt-2 text-sm text-red-700">
                    <ul role="list" class="list-disc space-y-1 pl-5">
                      <li v-for="(er, k) in formError.errors" :key="k">{{ er[0] }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="mt-4">
      <button
          @click="connectExchangeAccount"
          type="button"
          class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
          :class="[
            formProcessing ? 'opacity-50 pointer-events-none' : '',
            $theme.inverseBaseBg,
          ]"
      >
        <template v-if="formProcessing">
          <ButtonSpinner />
        </template>
        {{ $t('connect_exchange') }}
      </button>
    </div>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {
  CheckIcon,
  ChevronUpDownIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/vue/20/solid'

export default {
  components: {
    LoadingBlock,
    ButtonSpinner,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    InformationCircleIcon,
    XCircleIcon
  },

  methods: {
    connectExchangeAccount() {
      this.formProcessing = true;

      if (this.selectedExchange) {
        this.form.type_exchange = this.selectedExchange.slug;
      }

      this.$api.post('/exchanges/new', this.form).then((result) => {
        if (result.data.status === 1) {
          this.$emit('action-success', 1);
        } else {
          this.formError.message = 'Error connect'
          this.formError.errors = {
            error_connect: [result.data.message]
          }
          this.formProcessing = false;
        }
      }).catch(e => {
        this.formError = e.response.data;
        this.formProcessing = false;
      })
    },

    copyToClipboard() {
      const copyText = this.$refs.ipAddressInput;
      copyText.select();
      document.execCommand('copy');
      alert(this.$t('copied_ip_address') + ' ' + this.allowedIPs);
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      selectedExchange: null,
      allowedIPs: this.$settings.list_ips,
      exchanges: [
        {
          id: 1,
          name: 'Binance',
          slug: 'binance',
        },
      ],
      formError: {
        message: '',
        errors: null,
        status: 0,
      },
      form: {
        type_exchange: 'binance',
        api_key: '',
        api_secret: '',
        api_passphrase: '',
      },
      formProcessing: false,
    }
  },

  mounted() {
    this.loading = false
  },
}
</script>