<template>
  <div>
    <dl class="mx-auto grid gap-px grid-cols-2 py-2.5" :class="[$theme.cardBorderColor]">
      <div class="flex flex-col flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
        <dt class="text-sm font-medium h-3 w-20 rounded-xl animate-pulse" :class="[$theme.placeBg]"></dt>
        <dd class="flex-none text-xl font-medium tracking-tight h-4 w-12 rounded-xl animate-pulse" :class="[$theme.placeBg]"></dd>
      </div>
      <div class="flex flex-col flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
        <dt class="text-sm font-medium h-3 w-20 rounded-xl animate-pulse" :class="[$theme.placeBg]"></dt>
        <dd class="flex-none text-xl font-medium tracking-tight h-4 w-12 rounded-xl animate-pulse" :class="[$theme.placeBg]"></dd>
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  components: {
  },
}
</script>

<style>
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 1.5s ease-in-out infinite;
}
</style>
