<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('my_profile') }}</h3>

    <div class="mt-3">
      <div class="rounded-xl px-3" :class="[$theme.cardBg]">
        <a href="javascript:void(0)" :class="['flex flex-col py-2 border-b', $theme.cardBorderColor]">
          <div class="text-xs opacity-60">{{ $t('name') }}</div>
          <div>{{ $user.name }}</div>
        </a>
        <a href="javascript:void(0)" :class="['flex flex-col py-2 border-b', $theme.cardBorderColor]">
          <div class="text-xs opacity-60">{{ $t('phone') }}</div>
          <div>{{ $user.phone }}</div>
        </a>
        <a href="javascript:void(0)" :class="['flex flex-col py-2']">
          <div class="text-xs opacity-60">E-mail</div>
          <div>{{ $user.email }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";

export default {
  components: {
  },

  methods: {
  },

  data() {
    return {
      error: null,
    }
  },

  mounted() {
    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>