<template>
  <div class="pt-4 px-4 pb-20">
    <div class="mb-5">
      <h1 class="text-3xl font-bold">
        {{ $t('statistics') }}
      </h1>
      <div class="opacity-50">{{ $t('according_your_refs') }}</div>
    </div>

    <div class="block">
      <nav class="flex space-x-4 mb-5" aria-label="Tabs">
        <a
            v-for="tab in tabs"
            :key="tab.key"
            href="javascript:void(0)"
            :class="[
              tab.key === activePeriod ? `${$theme.activeTabBg} ${$theme.activeTabTextColor}` : $theme.tabTextColor,
              'rounded-md px-3 py-2 text-sm font-medium'
            ]"
            @click="onChangePeriod(tab.key)"
        >{{ tab.name }}</a>
      </nav>
    </div>

    <template v-if="loading">
      <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
        <dl class="mx-auto grid gap-px grid-cols-2">
          <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
            <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">
              {{ $t('income') }}
            </dt>
            <dd :class="[stats.referral_income_up_percent < 0 ? $theme.dangerPnlColor : $theme.successPnlColor, 'text-xs font-medium']">
              {{ stats.referral_income_up_percent }}%
            </dd>
            <dd
                class="w-full flex-none text-xl font-medium tracking-tight"
                :class="[stats.referral_income < 0 ? $theme.dangerPnlColor : $theme.successPnlColor]"
            >
              ${{ stats.referral_income }}
            </dd>
          </div>
          <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
            <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">{{ $t('referrals') }}</dt>
            <dd class="w-full flex-none text-xl font-medium tracking-tight" :class="[$theme.baseTextColor]">
              {{ stats.referrals }}
            </dd>
          </div>
        </dl>
        <RefStatDetails :details="stats.details" />
      </div>

      <template v-if="stats.campaigns.length > 0">
        <div v-for="(campaign, campaignIndex) in stats.campaigns" :key="campaignIndex" class="mt-4">
          <div class="mb-1 flex items-center justify-between">
            {{ campaign.title }}
          </div>
          <div class="rounded-xl overflow-hidden" :class="[$theme.cardBg]">
            <dl class="mx-auto grid gap-px grid-cols-2">
              <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
                <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">
                  {{ $t('income') }}
                </dt>
                <dd
                    class="w-full flex-none text-xl font-medium tracking-tight"
                    :class="[campaign.referral_income < 0 ? $theme.dangerPnlColor : $theme.successPnlColor]"
                >
                  ${{ campaign.referral_income }}
                </dd>
              </div>
              <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-3 py-3">
                <dt class="text-sm font-medium" :class="[$theme.cardLabelTextColor]">{{ $t('referrals') }}</dt>
                <dd class="w-full flex-none text-xl font-medium tracking-tight" :class="[$theme.baseTextColor]">
                  {{ campaign.referrals }}
                </dd>
              </div>
            </dl>
            <RefStatDetails :details="campaign.details" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LoadingBlock from "@/components/LoadingBlock.vue";
import RefStatDetails from "@/components/elem/RefStatDetails.vue";
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";

export default {
  components: {
    LoadingBlock,
    RefStatDetails,
  },

  methods: {
    loadStats() {
      this.loading = true;

      this.$api.get(`/stats/refs?period=${this.activePeriod}`).then((result) => {
        console.log('result', result);
        this.stats = result.data;
        this.loading = false;
      });
    },

    onChangePeriod(period) {
      this.activePeriod = period;

      this.loadStats();
    },
  },

  data() {
    return {
      activePeriod: '7days',
      tabs: [
        {
          name: this.$t('7days'),
          key: '7days',
        },
        {
          name: this.$t('14days'),
          key: '14days',
        },
        {
          name: this.$t('30days'),
          key: 'month',
        },
      ],

      loading: true,

      stats: null,
    }
  },

  mounted() {
    this.loadStats();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
};
</script>