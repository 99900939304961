<template>
  <div>
    <template v-if="!thePnl">
      <span class="text-sm mt-0.5 opacity-60">0</span>
    </template>
    <template v-else>
      <div :class="[
        thePnl > 0 ? $theme.successPnlColor : '',
        thePnl < 0 ? $theme.dangerPnlColor : '',
        'text-sm mt-0.5'
      ]">
        <template v-if="thePnl > 0">
          +{{ roundAmount(thePnl, 6) }}
        </template>
        <template v-else>
          {{ roundAmount(thePnl, 6) }}
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import {roundAmount} from "@/helpers/numberHelper"

export default {
  props: [
      'pnl',
  ],
  computed: {
    thePnl() {
      return parseFloat(this.pnl);
    }
  },
  data() {
    return {
      roundAmount
    }
  }
}
</script>