<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('logs') }}</h3>

    <div class="mt-3">
      <template v-if="loadingLogs">
        <div class="rounded-xl overflow-hidden mt-4" :class="[$theme.cardBg]">
          <LoadingBlock />
        </div>
      </template>
      <template v-else>
        <div class="rounded-xl overflow-hidden mt-4" :class="[$theme.cardBg]">
          <div v-for="(log, logIndex) in logs.data" :key="logIndex" @click="onShowDetail(log)" class="flex items-center justify-between px-3 py-2" :class="[
            $theme.cardBorderColor,
            logIndex !== (logs.data.length - 1) ? 'border-b' : ''
          ]">
            <div>
              <div class="text-xs">
                <span class="opacity-50">{{ getDateFormat(log.created_at) }}</span>
                <span :class="[
                  'text-white px-2 rounded-lg ml-2',
                  log.type === 'info' && 'bg-blue-600',
                  log.type === 'alert' && 'bg-indigo-600',
                  log.type === 'warning' && 'bg-yellow-400',
                  log.type === 'error' && 'bg-red-500',
                ]">{{ $t(log.type) }}</span>
              </div>
              <div class="text-sm">{{ $t(log.key) }}</div>
            </div>
            <div class="flex items-center text-sm" :class="[$theme.cardLabelTextColor]">
              <ChevronRightIcon class="w-5" />
            </div>
          </div>
        </div>

        <template v-if="logs.last_page > form.page">
          <button
              @click="onLoadMore"
              class="w-full text-sm py-3 rounded-xl font-medium"
              :class="[
                  loadingMore ? 'opacity-60 pointer-events-none' : '',
                  $theme.outlineBtnBg,
                  $theme.outlineBtnTextColor,
              ]"
          >
            <template v-if="loadingMore">
              <ButtonSpinnerBlue />
            </template>
            {{ $t('load_more') }}
          </button>
        </template>
      </template>
    </div>
  </div>

  <TransitionRoot as="template" :show="showDetail">
    <Dialog class="relative z-50" @close="showDetail = false">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div class="fixed inset-0 z-50 flex items-end justify-center">
        <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300"
            enter-from="translate-y-full opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="transform transition ease-in-out duration-300"
            leave-from="translate-y-0 opacity-100"
            leave-to="translate-y-full opacity-0"
        >
          <DialogPanel class="w-full bg-white rounded-t-2xl px-4 pt-5 pb-10 text-left shadow-xl">
            <div class="text-xl">{{ $t(detail.key) }}</div>
            <table class="min-w-full divide-y divide-gray-300 mt-5">
              <tbody class="divide-y divide-gray-200">
              <tr v-for="[key, value] in Object.entries(JSON.parse(detail.extra_data))" :key="key">
                <td class="whitespace-nowrap py-2 text-sm text-gray-500">{{ key }}</td>
                <td class="whitespace-nowrap py-2 text-sm text-gray-500">{{ value }}</td>
              </tr>
              </tbody>
            </table>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import LoadingBlock from "@/components/LoadingBlock.vue";
import {ChevronRightIcon} from "@heroicons/vue/24/outline";
import ButtonSpinnerBlue from "@/components/ButtonSpinnerBlue.vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    ButtonSpinnerBlue,
    ChevronRightIcon,
    LoadingBlock,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },

  methods: {
    getDateFormat(dateString) {
      const date = new Date(dateString);

      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };

      return date.toLocaleString('en-GB', options).replace(',', '');
    },

    onShowDetail(detail) {
      this.detail = detail;
      this.showDetail = true;
    },

    loadLogs() {
      this.$api.get('/user/logs').then((result) => {
        this.logs = result.data;
        this.meta = result.data.meta;
        this.loadingLogs = false;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
    onLoadMore() {
      this.loadingMore = true;
      this.form.page += 1;

      this.$api.get('/user/logs', {
        params: this.form
      }).then((result) => {
        this.logs.data = [...this.logs.data, ...result.data.data];
        this.loadingMore = false;
      }).catch(e => {
        console.log(e);
        this.error = e;
      });
    },
  },

  data() {
    return {
      error: null,
      loadingLogs: true,
      loadingMore: false,
      logs: [],
      form: {
        page: 1
      },

      detail: null,
      showDetail: false,
    }
  },

  mounted() {
    addBackButton(this.$router);

    this.loadLogs();
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>