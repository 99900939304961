<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('language') }}</h3>

    <div class="mt-3">
      <div class="rounded-xl px-3" :class="[$theme.cardBg]">
        <a href="javascript:void(0)"
          v-for="(language, langIndex) in languages" :key="langIndex"
          :class="[
            'flex justify-between items-center py-2',
            langIndex === (languages.length - 1) ? '' : `border-b ${$theme.cardBorderColor}`
          ]"
          @click="onChangeLanguage(language)"
        >
          <div class="flex flex-col">
            <div>{{ language.title }}</div>
            <div class="text-xs" :class="[$theme.cardLabelTextColor]">{{ language.native_title }}</div>
          </div>
          <div>
            <template v-if="language.language_code === $user.language_code && processing === null">
              <CheckCircleIcon class="w-5" :class="[$theme.navActiveTextColor2]" />
            </template>
            <template v-if="parseInt(processing) === parseInt(language.id)">
              <LoadingBlock />
            </template>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import {CheckCircleIcon} from '@heroicons/vue/20/solid'
import LoadingBlock from "@/components/LoadingBlock.vue";

export default {
  components: {
    LoadingBlock,
    CheckCircleIcon
  },

  methods: {
    loadLanguages() {
      this.$api.get('/languages').then((result) => {
        console.log(result.data.data)
        this.languages = result.data.data;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    onChangeLanguage(language) {
      console.log('language', language);
      this.processing = language.id;

      this.$api.post('/user/update-language', {
        language_code: language.language_code
      }).then((result) => {
        console.log('Settings updated.', result);

        //this.$i18n.locale = language.language_code;
        // this.$user.language_code = language.language_code;
        // this.processing = null;

        window.location.reload();
      }).catch(e => {
        alert(e.message);
        this.error = e;
        this.processing = null;
      });
    }
  },

  data() {
    return {
      languages: [],
      error: null,
      processing: null,
    }
  },

  mounted() {
    this.loadLanguages();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>