<template>
  <div>
    <div v-if="items" class="w-full px-3 py-2">
      <div class="mx-auto max-w-4xl divide-y divide-gray-600/10">
        <h2 class="font-medium tracking-tight" :class="[$theme.baseTextColor]">
          {{ $t('frequently_asked_questions') }}
        </h2>
        <dl class="mt-2 divide-y divide-gray-600/10">
          <Disclosure as="div" v-for="item in items" :key="item.id" class="pt-3 pb-3" v-slot="{ open }">
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-sm" :class="[$theme.cardLabelTextColor]">
                <span>{{ item.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <template v-if="item.answer.blocks">
                <p class="text-sm" :class="[$theme.cardLabelTextColor]">{{ item.answer.blocks[0].data.text }}</p>
              </template>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

export default {
  props: [
    'items'
  ],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon,
  },
}
</script>