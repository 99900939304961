<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('notifications') }}</h3>

    <div class="mt-3">
      <div class="rounded-xl" :class="[$theme.cardBg]">
        <div class="px-3">
          <SwitchGroup
            v-for="(item, index) in notificationList"
            :key="index"
            as="div"
            class="flex items-center justify-between py-2"
            :class="[index === (notificationList.length - 1) ? '' : $theme.cardBorderColor + ' border-b']"
          >
            <span class="flex flex-grow flex-col">
              <SwitchLabel
                as="span"
                class="text-sm font-medium leading-6"
                :class="[
                  item.key === 'system_errors' || item.key === 'connection_established' || item.key === 'connection_not_established' ? 'text-red-600' : $theme.baseTextColor
                ]"
                passive
              >
                {{ $t(item.key) }}
              </SwitchLabel>
            </span>
            <Switch
                @click="toggleNotification(item.key)"
                :class="[
                  notifications.indexOf(item.key) !== -1 ? $theme.switchBgActive : $theme.switchBgInactive,
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
                  $theme.switchRingFocus
                ]"
            >
              <span aria-hidden="true" :class="[notifications.indexOf(item.key) !== -1 ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
          </SwitchGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addBackButton, hideBackButton } from '@/helpers/telegramHelper'
import {Switch, SwitchGroup, SwitchLabel} from '@headlessui/vue'

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel
  },

  methods: {
    toggleNotification(key) {
      console.log('Change notification: ', key);

      const index = this.notifications.indexOf(key);
      if (index === -1) {
        this.notifications.push(key);
      } else {
        this.notifications.splice(index, 1);
      }

      this.updateUserSettings()
    },

    updateUserSettings() {
      this.$api.post('/user/update-settings', {
        notifications: this.notifications,
      }).then((result) => {
        console.log('Settings updated.', result)
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },

    loadUser() {
      this.$api.get('/user').then((result) => {
        this.user = result.data.data
        this.notifications = this.user.settings.notifications
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    },
  },

  data() {
    return {
      notifications: [],

      notificationList: [
        {
          key: 'order_opened',
        },
        {
          key: 'closing_order',
        },
        {
          key: 'order_stoploss',
        },
        {
          key: 'order_change_stoploss',
        },
        {
          key: 'order_takeprofit',
        },
        {
          key: 'partial_order_fixing',
        },
        {
          key: 'new_referral',
        },
        {
          key: 'referral_payment_success',
        },
        {
          key: 'referral_payment_declined',
        },
        {
          key: 'payout_request_created',
        },
        {
          key: 'tariff_payment_success',
        },
        {
          key: 'connection_established',
        },
        {
          key: 'connection_not_established',
        },
        {
          key: 'system_errors',
        },
      ],
    }
  },

  mounted() {
    this.loadUser();

    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>