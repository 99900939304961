<template>
  <div class="pt-4">
    <h3 class="text-3xl font-bold mb-5">
      System logs
    </h3>
    <template v-if="loading">
      <div class="rounded-lg" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div class="break-words pb-20">
        <div
            v-for="(log, i) in logs"
            :key="i"
            class="text-xs whitespace-normal hover:bg-yellow-200"
            :class="[
                containsWord(log, 'ERROR') ? 'bg-red-100' : ''
            ]"
        >
          {{ log }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'

export default {
  components: {
    LoadingBlock,
  },

  methods: {
    containsWord(str, word) {
      // Создаем регулярное выражение для поиска слова
      const regex = new RegExp(`\\b${word}\\b`, 'i');
      // Проверяем, содержит ли строка слово
      return regex.test(str);
    },

    loadLogs() {
      this.$api.get('/logs').then((result) => {
        console.log(result.data.logs)
        this.logs = result.data.logs
        this.loading = false
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      logs: []
    }
  },

  mounted() {
    this.loadLogs();
  }
}
</script>