<template>
  <div class="ui-order-item rounded-lg" :class="[$theme.cardBg]">
    <div class="flex px-2 pt-2 pb-2 border-b" :class="[$theme.cardBorderColor]">
      <div class="w-full flex items-center justify-between font-semibold whitespace-nowrap">
        <div class="flex items-center">
          <div>
            <div class="relative flex items-center">
              <CryptoIcon :symbol="order.symbol" />
              <img src="../../../node_modules/cryptocurrency-icons/svg/color/usdt.svg" class="w-5 relative right-2">
              <span>{{ order.symbol }} x{{ parseInt(order.leverage) }}</span>
            </div>
          </div>
          <div class="pl-3">
            <template v-if="order.position === 'long'">
              <div class="text-white text-sm font-normal px-2 rounded-lg" :class="[$theme.bgSuccessPnlColor]">LONG</div>
            </template>
            <template v-else>
              <div class="text-white text-sm font-normal px-2 rounded-lg" :class="[$theme.bgDangerColor]">SHORT</div>
            </template>
          </div>
        </div>
        <div v-if="is_edit === true" class="flex items-center font-normal text-sm">
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton class="flex items-center rounded-full focus:outline-none" :class="[$theme.menuBtnBg, $theme.menuBtnTextColor]">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a @click="closePosition(order)" href="javascript:void(0)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                      {{ $t('close_position') }}
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 px-2 pt-2 pb-2">
      <div class="whitespace-nowrap">
        <div class="text-xs" :class="[$theme.cardLabelTextColor]">
          <template v-if="order.close_position">
            {{ $t('in_position') }}
          </template>
          <template v-else>
            {{ $t('margin') }}
          </template>
        </div>
        <template v-if="order.close_position">
          <div class="text-sm">
            {{ roundAmount(order.orig_amount_margin, 3) }} USDT
          </div>
        </template>
        <template v-else>
          <TheTooltip :id="`o_${order.id}`" :message="`${$t('in_position')} ${order.amount} USDT`">
            <div class="text-sm">{{ roundAmount(order.amount_margin) }} USDT</div>
          </TheTooltip>
        </template>
      </div>
      <div class="whitespace-nowrap">
        <div class="text-xs" :class="[$theme.cardLabelTextColor]">{{ $t('size') }}</div>
        <div class="text-sm mt-0.5" :class="[
          order.position === 'long' ? $theme.successPnlColor : $theme.dangerPnlColor
        ]">
          {{ order.close_position ? parseFloat(order.executed_qty) : parseFloat(order.current_qty) }} {{ order.symbol.replace('USDT', '') }}
        </div>
      </div>
      <div class="whitespace-nowrap">
        <div class="text-xs" :class="[$theme.cardLabelTextColor]">P&L</div>
        <ShowPnl :pnl="order.close_position ? order.realised_pnl : order.pnl" />
      </div>
    </div>
    <Disclosure as="div" class="w-full px-2 py-1 text-sm border-t" :class="[$theme.cardBorderColor]" v-slot="{ open }">
      <dt class="flex justify-between items-center w-full">
        <div class="flex items-center">
          <img :src="`/img/${order.exchange.exchange_slug}.png`" class="w-4 rounded-full mr-1">
          {{ capitalizeFirstLetter(order.exchange.exchange_slug) }} <span class="opacity-50 pl-2">ID: {{ order.exchange.id }}</span>
        </div>
        <DisclosureButton class="flex items-start text-left" :class="[$theme.cardLabelTextColor]">
          <span class="text-sm">{{ $t('detail') }}</span>
          <span class="ml-1 flex h-5 items-center">
            <PlusSmallIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
            <MinusSmallIcon v-else class="h-5 w-5" aria-hidden="true" />
          </span>
        </DisclosureButton>
      </dt>
      <DisclosurePanel as="dd" class="w-full pt-4">
        <div class="grid grid-cols-2">
          <div class="mb-1">
            <div class="text-xs" :class="[$theme.cardLabelTextColor]">Средняя цена</div>
            <div class="text-sm">
              {{ order.price || '0' }}
            </div>
          </div>
          <div class="mb-1">
            <div class="text-xs" :class="[$theme.cardLabelTextColor]">Цена ликвидации</div>
            <div class="text-sm" :class="[$theme.dangerPnlColor]">
              {{ order.liquidation_price || '0' }}
            </div>
          </div>
          <div class="mb-1">
            <div class="text-xs" :class="[$theme.cardLabelTextColor]">TP/SL</div>
            <div class="text-sm">
              <template v-if="order.take_price">
                <span :class="[$theme.successPnlColor]">{{ order.take_price }}</span>
              </template>
              <template v-else>
                <span>-</span>
              </template>
              /
              <template v-if="order.stop_price">
                <span v-if="order.position === 'long'" :class="[$theme.dangerPnlColor]">
                  {{ parseFloat(order.stop_price) > 0.00001 ? order.stop_price : '≤0.00001' }}
                </span>
                <span v-if="order.position === 'short'" :class="[$theme.successPnlColor]">
                  {{ parseFloat(order.stop_price) < 999999 ? order.stop_price : '≥999999' }}
                </span>
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </div>
          </div>
          <div class="mb-1">
            <div class="text-xs" :class="[$theme.cardLabelTextColor]">{{ $t('created_at') }}</div>
            <div class="text-sm">{{ order.created_at_format }}</div>
          </div>
          <div v-if="order.status === 'FILLED'" class="mb-1">
            <div class="text-xs" :class="[$theme.cardLabelTextColor]">{{ $t('closing_at') }}</div>
            <div class="text-sm">{{ order.closed_at_format || '-' }}</div>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>
<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {EllipsisVerticalIcon} from "@heroicons/vue/20/solid";
import CryptoIcon from "@/components/icons/CryptoIcon.vue";
import TheTooltip from "@/components/elem/TheTooltip.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import ShowPnl from "@/components/elem/ShowPnl.vue";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import {roundAmount} from "@/helpers/numberHelper"

export default {
  props: [
    'order',
    'is_edit'
  ],

  components: {
    EllipsisVerticalIcon,
    CryptoIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TheTooltip,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon,
    ShowPnl
  },

  methods: {
    closePosition(order) {
      console.log('Load orders...');

      this.$api.post(`/orders/${order.id}/close`).then((result) => {
        console.log(result.data)
        this.$emit('close-position', 1);
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      capitalizeFirstLetter,
      roundAmount
    }
  },
};
</script>