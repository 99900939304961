<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('terms') }}</h3>

    <div class="mt-3">
      <template v-if="documents.length">
        <TermDocuments :documents="documents" />
      </template>
    </div>
  </div>
</template>
<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import TermDocuments from "@/components/TermDocuments.vue";

export default {
  components: {
    TermDocuments,
  },

  methods: {
    loadDocuments() {
      this.$api.get('/documents?bot_id=1').then(result => {
        this.documents = result.data.data;
      });
    },
  },

  data() {
    return {
      error: null,
      processing: null,
      documents: [],
    }
  },

  mounted() {
    addBackButton(this.$router);

    this.loadDocuments();
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>