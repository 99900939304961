<template>
  <dd class="mt-4 text-sm sm:col-span-2 sm:mt-0" :class="[$theme.baseTextColor]">
    <ul role="list" class="divide-y rounded-xl border" :class="[$theme.cardBg, $theme.cardBorderColor, $theme.cardDivideColor1]">
      <li v-for="document in documents" :key="document.id" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
        <div class="flex w-0 flex-1 items-center">
          <PaperClipIcon class="h-5 w-5 flex-shrink-0" :class="[$theme.cardLabelTextColor]" aria-hidden="true" />
          <div class="ml-4 flex min-w-0 flex-1 gap-2">
            <span class="truncate font-medium">{{ document.title }}</span>
          </div>
        </div>
        <div class="ml-3 flex-shrink-0">
          <a :href="document.file" target="_blank" class="font-medium" :class="[$theme.navActiveTextColor2]">{{ $t('view') }}</a>
        </div>
      </li>
    </ul>
  </dd>
</template>
<script>
import {PaperClipIcon} from "@heroicons/vue/24/outline";

export default {
  props: [
    'documents'
  ],

  components: {
    PaperClipIcon
  },
};
</script>