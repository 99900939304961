<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('payment_cards') }}</h3>

    <div class="mt-3">
      <template v-if="loadingPaymentCards">
        <div class="rounded-xl overflow-hidden mt-4" :class="[$theme.cardBg]">
          <LoadingBlock />
        </div>
      </template>
      <template v-else>
        <div class="rounded-xl overflow-hidden mt-4" :class="[$theme.cardBg]">
          <div v-for="paymentCard in paymentCards" :key="paymentCard.id" class="flex items-center justify-between px-3 border-b py-0.5" :class="[$theme.cardBorderColor]">
            <div class="flex items-center">
              <template v-if="paymentCard.payment_method.card.brand === 'visa'">
                <img src="/img/brand-card/visa.svg" class="w-10 mr-2" />
              </template>
              <template v-else-if="paymentCard.payment_method.card.brand === 'mastercard'">
                <img src="/img/brand-card/mastercard.svg" class="w-10 mr-2" />
              </template>
              <template v-else>
              </template>
              <div>**** **** **** {{ paymentCard.payment_method.card.last4 }}</div>
            </div>
            <div class="opacity-50">{{ paymentCard.payment_method.card.exp_month }}/{{ paymentCard.payment_method.card.exp_year }}</div>
          </div>
          <router-link to="/link-card" class="flex items-center px-3 py-2" :class="[$theme.navActiveTextColor2]">
            <div class="flex items-center">
              <PlusIcon class="w-6 mr-2" /> {{ $t('link_card') }}
            </div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import LoadingBlock from "@/components/LoadingBlock.vue";
import {PlusIcon} from "@heroicons/vue/24/outline";

export default {
  components: {
    PlusIcon,
    LoadingBlock
  },

  methods: {
    loadPaymentCards() {
      this.$api.get('/user/payment-cards').then((result) => {
        this.paymentCards = result.data;
        this.loadingPaymentCards = false;
      }).catch(e => {
        alert(e.message)
        this.error = e
      })
    }
  },

  data() {
    return {
      error: null,
      loadingPaymentCards: true,
      paymentCards: []
    }
  },

  mounted() {
    addBackButton(this.$router);

    this.loadPaymentCards();
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>