<template>
  <div class="pt-4 pb-24 px-4">
    <h3 class="text-3xl font-bold mb-5">{{ $t('security') }}</h3>

    <template v-if="!is_2fa_enabled && !is_show_qr_code">
      <div class="mt-3">
        <div class="rounded-xl px-3 py-3 flex flex-col" :class="[$theme.cardBg]">
          <div class="font-semibold">{{ $t('2fa_auth') }}</div>
          <div class="mb-3 text-sm">
            {{ $t('enable_2fa_desc') }}
          </div>
          <button
            @click="enable2FA"
            class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
            :class="[$theme.inverseBaseBg]"
          >
            {{ $t('enable_2fa') }}
          </button>
        </div>
      </div>
    </template>

    <template v-if="!is_2fa_enabled && is_show_qr_code">
      <div class="mt-3">
        <div class="rounded-xl px-3" :class="[$theme.cardBg]">
          <div class="flex border-b pt-2 pb-2" :class="[$theme.cardBorderColor]">
            <div class="pt-3 pb-3">
              <QrCodeIcon class="w-8 h-8" />
            </div>
            <div class="pl-2">
              <div>
                {{ $t('setup_2fa_you_device') }}
              </div>
              <div class="text-xs">{{ $t('scan_qr_code') }}</div>
            </div>
          </div>
          <div class="py-3">
            <div v-if="qrcode" class="flex items-center justify-center flex-col border-b pb-3" :class="[$theme.cardBorderColor]">
              <img :src="qrcode" alt="QR Code">
              <p class="text-sm mt-3">{{ $t('scan_qr_code_or') }}</p>

              <div class="relative w-full mt-2">
                <input
                    readonly
                    v-model="code_manual"
                    type="text"
                    id="code_manual"
                    ref="code_manual"
                    class="block w-full rounded-xl border-0 py-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
                />
                <button class="copy-button absolute right-2 top-2" :class="[$theme.navActiveTextColor2]" @click="copyToClipboard">
                  {{ $t('copy') }}
                </button>
              </div>
            </div>
            <form @submit.prevent="verify2FA" class="pt-3 pb-3">
              <label for="2fa_code" class="text-sm mb-2">{{ $t('enter_2fa_code') }}</label>
              <input
                  type="text"
                  v-model="code"
                  id="2fa_code"
                  class="block w-full rounded-xl border-0 py-2 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                  :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
              />
              <div class="mt-3">
                <button
                  type="submit"
                  class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
                  :class="[isFormProcessing ? 'opacity-50 pointer-events-none' : '', $theme.inverseBaseBg]"
                >
                  <template v-if="isFormProcessing">
                    <ButtonSpinner />
                  </template>
                  {{ $t('check') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>

    <template v-if="is_2fa_enabled">
      <div class="mt-3">
        <div class="rounded-xl px-3 py-4" :class="[$theme.cardBg]">
          <div class="flex flex-col items-center justify-center">
            <ShieldCheckIcon class="w-10 h-10 text-green-500" />
            <div class="mt-2 font-semibold" :class="[$theme.baseTextColor]">{{ $t('2fa_enabled') }}</div>
            <p class="text-sm text-center" :class="[$theme.cardLabelTextColor]">
              {{ $t('2fa_enabled_desc') }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import {QrCodeIcon} from "@heroicons/vue/24/outline";
import {ShieldCheckIcon} from "@heroicons/vue/24/solid";
import ButtonSpinner from "@/components/ButtonSpinner.vue";

export default {
  components: {
    ButtonSpinner,
    QrCodeIcon,
    ShieldCheckIcon
  },

  methods: {
    async enable2FA() {
      try {
        const response = await this.$api.post('/2fa/enable');
        this.qrcode = response.data.qrcode;
        this.code_manual = response.data.code_manual;
        this.is_show_qr_code = true;
      } catch (error) {
        console.error('Error enabling 2FA:', error);
      }
    },
    async verify2FA() {
      this.isFormProcessing = true;

      try {
        const response = await this.$api.post('/2fa/verify', {
          the_2fa_code: this.code
        });

        console.log(response);
        this.isFormProcessing = false;
        this.is_2fa_enabled = true;
        this.is_show_qr_code = true;
      } catch (error) {
        alert(this.$t('invalid_2fa_code'));
        this.isFormProcessing = false;
      }
    },

    copyToClipboard() {
      const copyText = this.$refs.code_manual;
      copyText.select();
      document.execCommand('copy');
      alert(this.$t('copied_setup_key') + ' ' + this.code_manual);
    }
  },

  data() {
    return {
      error: null,
      code: '',
      qrcode: '',
      code_manual: '',

      is_2fa_enabled: this.$user.is_2fa_enabled,
      is_show_qr_code: false,

      isFormProcessing: false,
    }
  },

  mounted() {
    addBackButton(this.$router);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>