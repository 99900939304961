export default {
    default: {
        baseBg: 'bg-white',
        secondBaseBg: 'bg-custom-gray',
        baseTextColor: 'text-black',
        activeTabBg: 'bg-blue-100',
        activeTabTextColor: 'text-blue-700',
        tabTextColor: 'text-gray-500',
        cardBg: 'bg-white',
        cardBorderColor: 'border-gray-100',
        cardLabelTextColor: 'text-gray-500',
        lightButtonBg: 'bg-gray-50',
        lightButtonTextColor: 'text-gray-600',
        navBg: 'bg-white',
        navBorderColor: 'border-gray-100',
        navActiveBg: 'bg-gray-100',
        navActiveTextColor: 'text-blue-600',
        navActiveTextColor2: 'text-blue-600',
        borderColorCoin: 'border-white',
        dangerPnlColor: 'text-red-600',
        successPnlColor: 'text-green-600',
        bgDangerColor: 'bg-red-600',
        bgSuccessPnlColor: 'bg-green-600',
        loaderFill: 'fill-blue-600',
        cardDivideColor: 'divide-gray-200',
        cardDivideColor1: 'divide-gray-100',
        menuBtnBg: 'bg-gray-100',
        menuBtnTextColor: 'text-gray-400',
        chartPlaceBg: 'bg-white',
        placeBg: 'bg-gray-200',
        placeTextColor: 'text-gray-200',
        outlineBtnBg: 'bg-blue-100',
        outlineBtnTextColor: 'text-blue-700',
        spinnerTextColor: 'text-blue-700',
        inputRingColor: 'ring-gray-300',
        inputBorderColor: 'border-gray-300',
        inputRingFocusColor: 'focus:ring-blue-600',
        inputPlaceTextColor: 'placeholder:text-gray-400',
        iconBtnBg: 'bg-gray-100',
        avatarBg: 'bg-blue-50',
        switchBgActive: 'bg-blue-600',
        switchBgInactive: 'bg-gray-200',
        switchRingFocus: 'focus:ring-blue-600',
        bgGuideTour: 'bg-gradient-to-r from-blue-100 to-white',
        inverseBaseBg: 'bg-blue-600',
        checkboxBgInactive: 'bg-white',
        infoAlertBg: 'bg-blue-50',
        infoAlertTextColor: 'text-blue-700',
        infoAlertIconColor: 'text-blue-400',
        radioActiveBorderColor: 'border-blue-600',
        chartTheme: 'light'
    },
    bworld: {
        baseBg: 'bg-stone-900',
        secondBaseBg: 'bg-gradient-to-tr from-stone-900 to-yellow-900',
        baseTextColor: 'text-white',
        activeTabBg: 'bg-white/15',
        activeTabTextColor: 'text-white',
        tabTextColor: 'text-white/65',
        cardBg: 'bg-white/15',
        cardBorderColor: 'border-white/20',
        cardLabelTextColor: 'text-white/65',
        lightButtonBg: 'bg-white/15',
        lightButtonTextColor: 'text-white/65',
        navBg: 'bg-gradient-to-tr from-stone-900 to-yellow-900',
        navBorderColor: 'border-white/20',
        navActiveBg: 'bg-white/10',
        navActiveTextColor: 'text-white',
        navActiveTextColor2: 'text-yellow-300',
        borderColorCoin: 'border-transparent bg-transparent',
        dangerPnlColor: 'text-red-600',
        successPnlColor: 'text-lime-600',
        bgDangerColor: 'bg-red-600',
        bgSuccessPnlColor: 'bg-lime-600',
        loaderFill: 'fill-yellow-600',
        cardDivideColor: 'divide-white/20',
        cardDivideColor1: 'divide-white/10',
        menuBtnBg: 'bg-transparent',
        menuBtnTextColor: 'text-white/80',
        chartPlaceBg: 'bg-transparent',
        placeBg: 'bg-white/15',
        placeTextColor: 'text-white/15',
        outlineBtnBg: 'bg-yellow-100/15',
        outlineBtnTextColor: 'text-yellow-100',
        spinnerTextColor: 'text-yellow-300',
        inputRingColor: 'ring-white/20',
        inputBorderColor: 'border-white/20',
        inputRingFocusColor: 'focus:ring-yellow-300',
        inputPlaceTextColor: 'placeholder:text-white/20',
        iconBtnBg: 'bg-white/30',
        avatarBg: 'bg-yellow-50/20',
        switchBgActive: 'bg-yellow-400',
        switchBgInactive: 'bg-white/20',
        switchRingFocus: 'focus:ring-yellow-400',
        bgGuideTour: 'bg-gradient-to-r from-yellow-900 to-stone-900',
        inverseBaseBg: 'bg-yellow-500',
        checkboxBgInactive: 'bg-white/20',
        infoAlertBg: 'bg-yellow-50',
        infoAlertTextColor: 'text-yellow-700',
        infoAlertIconColor: 'text-yellow-400',
        radioActiveBorderColor: 'border-yellow-400',
        chartTheme: 'dark'
    },
    bworldai: {
        baseBg: 'bg-stone-900',
        secondBaseBg: 'bg-gradient-to-tr from-stone-900 to-yellow-900',
        baseTextColor: 'text-white/90',
        activeTabBg: 'bg-white/15',
        activeTabTextColor: 'text-white',
        tabTextColor: 'text-white/70',
        cardBg: 'bg-white/20',
        cardBorderColor: 'border-white/25',
        cardLabelTextColor: 'text-white/70',
        lightButtonBg: 'bg-white/20',
        lightButtonTextColor: 'text-white/70',
        navBg: 'bg-gradient-to-tr from-stone-900 to-yellow-900',
        navBorderColor: 'border-white/20',
        navActiveBg: 'bg-white/20',
        navActiveTextColor: 'text-white',
        navActiveTextColor2: 'text-yellow-300',
        borderColorCoin: 'border-transparent bg-transparent',
        dangerPnlColor: 'text-red-500',
        successPnlColor: 'text-lime-500',
        bgDangerColor: 'bg-red-600',
        bgSuccessPnlColor: 'bg-lime-600',
        loaderFill: 'fill-yellow-600',
        cardDivideColor: 'divide-white/20',
        cardDivideColor1: 'divide-white/10',
        menuBtnBg: 'bg-transparent',
        menuBtnTextColor: 'text-white/80',
        chartPlaceBg: 'bg-transparent',
        placeBg: 'bg-white/15',
        placeTextColor: 'text-white/15',
        outlineBtnBg: 'bg-yellow-100/20',
        outlineBtnTextColor: 'text-yellow-100/90',
        spinnerTextColor: 'text-yellow-300',
        inputRingColor: 'ring-white/25',
        inputBorderColor: 'border-white/25',
        inputRingFocusColor: 'focus:ring-yellow-300',
        inputPlaceTextColor: 'placeholder:text-white/20',
        iconBtnBg: 'bg-white/30',
        avatarBg: 'bg-yellow-50/20',
        switchBgActive: 'bg-yellow-400',
        switchBgInactive: 'bg-white/20',
        switchRingFocus: 'focus:ring-yellow-400',
        bgGuideTour: 'bg-gradient-to-r from-yellow-900 to-stone-900',
        inverseBaseBg: 'bg-yellow-500',
        checkboxBgInactive: 'bg-white/20',
        infoAlertBg: 'bg-yellow-100',
        infoAlertTextColor: 'text-yellow-700',
        infoAlertIconColor: 'text-yellow-400',
        radioActiveBorderColor: 'border-yellow-400',
        chartTheme: 'dark'
    },
    dark_to_red: {
        baseBg: 'bg-gray-900',
        secondBaseBg: 'bg-gradient-to-tr from-gray-900 to-gray-800',
        baseTextColor: 'text-gray-200',
        activeTabBg: 'bg-red-700',
        activeTabTextColor: 'text-white',
        tabTextColor: 'text-gray-400',
        cardBg: 'bg-gradient-to-br from-gray-800 to-gray-700', // Градиент на карточках для выделения
        cardBorderColor: 'border-gray-600', // Более заметная граница
        cardLabelTextColor: 'text-gray-300',
        lightButtonBg: 'bg-gray-700',
        lightButtonTextColor: 'text-gray-200',
        navBg: 'bg-gray-900',
        navBorderColor: 'border-gray-700',
        navActiveBg: 'bg-red-600',
        navActiveTextColor: 'text-white',
        navActiveTextColor2: 'text-red-400',
        borderColorCoin: 'border-transparent bg-transparent',
        dangerPnlColor: 'text-red-500',
        successPnlColor: 'text-green-500',
        bgDangerColor: 'bg-red-600',
        bgSuccessPnlColor: 'bg-green-600',
        loaderFill: 'fill-red-600',
        cardDivideColor: 'divide-gray-700',
        cardDivideColor1: 'divide-gray-600',
        menuBtnBg: 'bg-transparent',
        menuBtnTextColor: 'text-gray-200',
        chartPlaceBg: 'bg-gray-800',
        placeBg: 'bg-gray-800',
        placeTextColor: 'text-gray-500',
        outlineBtnBg: 'bg-transparent',
        outlineBtnTextColor: 'text-red-600',
        spinnerTextColor: 'text-red-500',
        inputRingColor: 'ring-red-500',
        inputBorderColor: 'border-gray-700',
        inputRingFocusColor: 'focus:ring-red-600',
        inputPlaceTextColor: 'placeholder:text-gray-500',
        iconBtnBg: 'bg-gray-700',
        avatarBg: 'bg-red-800',
        switchBgActive: 'bg-red-600',
        switchBgInactive: 'bg-gray-700',
        switchRingFocus: 'focus:ring-red-500',
        bgGuideTour: 'bg-gradient-to-r from-gray-900 to-gray-800',
        inverseBaseBg: 'bg-red-700',
        checkboxBgInactive: 'bg-gray-700',
        infoAlertBg: 'bg-gray-700',
        infoAlertTextColor: 'text-red-500',
        infoAlertIconColor: 'text-red-400',
        radioActiveBorderColor: 'border-red-500',
        chartTheme: 'dark'
    },
    white_green: {
        baseBg: 'bg-white',
        secondBaseBg: 'bg-custom-gray',
        baseTextColor: 'text-black',
        activeTabBg: 'bg-green-100', // Зеленый фон активной вкладки
        activeTabTextColor: 'text-green-700', // Зеленый текст активной вкладки
        tabTextColor: 'text-gray-500',
        cardBg: 'bg-white',
        cardBorderColor: 'border-gray-100',
        cardLabelTextColor: 'text-gray-500',
        lightButtonBg: 'bg-gray-50',
        lightButtonTextColor: 'text-gray-600',
        navBg: 'bg-white',
        navBorderColor: 'border-gray-100',
        navActiveBg: 'bg-gray-100',
        navActiveTextColor: 'text-green-600', // Зеленый текст активного элемента навигации
        navActiveTextColor2: 'text-green-600', // Дополнительный зеленый цвет для активного элемента навигации
        borderColorCoin: 'border-white',
        dangerPnlColor: 'text-red-600',
        successPnlColor: 'text-green-600',
        bgDangerColor: 'bg-red-600',
        bgSuccessPnlColor: 'bg-green-600',
        loaderFill: 'fill-green-600', // Зеленое заполнение для загрузчика
        cardDivideColor: 'divide-gray-200',
        cardDivideColor1: 'divide-gray-100',
        menuBtnBg: 'bg-gray-100',
        menuBtnTextColor: 'text-gray-400',
        chartPlaceBg: 'bg-white',
        placeBg: 'bg-gray-200',
        placeTextColor: 'text-gray-200',
        outlineBtnBg: 'bg-green-100', // Зеленый фон контурной кнопки
        outlineBtnTextColor: 'text-green-700', // Зеленый текст контурной кнопки
        spinnerTextColor: 'text-green-700', // Зеленый цвет спиннера
        inputRingColor: 'ring-gray-300',
        inputBorderColor: 'border-gray-300',
        inputRingFocusColor: 'focus:ring-green-600', // Зеленое кольцо фокуса для ввода
        inputPlaceTextColor: 'placeholder:text-gray-400',
        iconBtnBg: 'bg-gray-100',
        avatarBg: 'bg-green-50', // Светло-зеленый фон для аватара
        switchBgActive: 'bg-green-600', // Активный фон переключателя – зеленый
        switchBgInactive: 'bg-gray-200',
        switchRingFocus: 'focus:ring-green-600', // Зеленый цвет кольца фокуса переключателя
        bgGuideTour: 'bg-gradient-to-r from-green-100 to-white', // Градиент для фона гида – светло-зеленый к белому
        inverseBaseBg: 'bg-green-600', // Инверсный базовый фон – зеленый
        checkboxBgInactive: 'bg-white',
        infoAlertBg: 'bg-green-50', // Светло-зеленый фон для информационного предупреждения
        infoAlertTextColor: 'text-green-700', // Зеленый текст для предупреждения
        infoAlertIconColor: 'text-green-400', // Зеленая иконка для предупреждения
        radioActiveBorderColor: 'border-green-600', // Зеленая граница для активного radio
        chartTheme: 'light'
    }
};