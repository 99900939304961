<template>
  <div
      class="fixed z-40 cursor-grab"
      :style="{ top: position.y + 'px', left: position.x + 'px' }"
      @pointerdown="startDrag"
      @click="handleContainerClick"
  >
    <Popover v-model:open="isPopoverOpen" class="relative" v-slot="{ open }">
      <PopoverButton
          ref="popoverButton"
          @click="onPopoverClick"
          class="w-full flex flex-col items-center focus:outline-none rounded-full"
          :class="[$theme.navActiveTextColor2, $theme.cardBg]"
      >
        <QuestionMarkCircleIcon v-if="!open" class="w-12" />
        <XCircleIcon v-if="open" class="w-12" />
      </PopoverButton>

      <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0 translate-y-1/2"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 translate-y-1/2"
      >
        <PopoverPanel class="absolute bottom-full right-0 z-10 mb-5 flex w-screen max-w-max pl-4">
          <div class="w-52 max-w-sm flex-auto rounded-xl text-sm leading-6 shadow-lg ring-1 bg-white ring-gray-900/5">
            <div class="px-3 py-2">
              <a
                  v-for="(link, index) in links"
                  :key="index"
                  :href="link.url"
                  class="flex items-center py-2"
                  :class="[index !== links.length - 1 ? 'border-b' : '']"
              >
                <img src="/img/tg_logo.webp" class="w-6 mr-2" /> {{ link.title }}
              </a>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import { QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/vue/16/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

export default {
  props: ["links"],
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    QuestionMarkCircleIcon,
    XCircleIcon,
  },
  data() {
    return {
      position: { x: 0, y: 0 }, // Начальная позиция
      dragging: false,
      startX: 0,
      startY: 0,
      hasDragged: false, // Флаг для отслеживания перетаскивания
      isPopoverOpen: false, // Контролируемое состояние Popover
    };
  },
  mounted() {
    // Установка начальной позиции внизу справа после рендера
    const buttonWidth = 48; // Ширина кнопки
    const buttonHeight = 48; // Высота кнопки
    this.position.x = window.innerWidth - buttonWidth - 20; // Отступ 20px от правого края
    this.position.y = window.innerHeight - buttonHeight - 80; // Отступ 80px от нижнего края

    // Обработчик изменения размера окна для корректировки позиции
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // Обеспечивает, что кнопка остаётся внутри видимой области при изменении размера окна
      this.position.x = Math.min(this.position.x, window.innerWidth - 48 - 20);
      this.position.y = Math.min(this.position.y, window.innerHeight - 48 - 80);
    },
    startDrag(event) {
      // Отвечаем только на первичную кнопку мыши
      if (event.pointerType === 'mouse' && event.button !== 0) return;

      this.dragging = true;
      this.hasDragged = false;

      // Захват указателя для получения всех событий перемещения и отпускания
      event.target.setPointerCapture(event.pointerId);

      this.startX = event.clientX - this.position.x;
      this.startY = event.clientY - this.position.y;

      window.addEventListener("pointermove", this.drag);
      window.addEventListener("pointerup", this.stopDrag);

      // Предотвращаем стандартное поведение для улучшения UX
      event.preventDefault();

      console.log('Начало перетаскивания:', this.startX, this.startY);
    },
    drag(event) {
      if (!this.dragging) return;

      const newX = event.clientX - this.startX;
      const newY = event.clientY - this.startY;

      // Ограничиваем движение внутри окна
      this.position.x = Math.max(0, Math.min(newX, window.innerWidth - 48 - 20));
      this.position.y = Math.max(0, Math.min(newY, window.innerHeight - 48 - 80));

      // Отмечаем, что произошло перетаскивание
      if (Math.abs(newX - this.position.x) > 2 || Math.abs(newY - this.position.y) > 2) {
        this.hasDragged = true;
      }

      console.log(`Перетаскивание: x=${this.position.x}, y=${this.position.y}`);
    },
    stopDrag(event) {
      if (!this.dragging) return;

      this.dragging = false;

      // Освобождаем захват указателя
      event.target.releasePointerCapture(event.pointerId);

      window.removeEventListener("pointermove", this.drag);
      window.removeEventListener("pointerup", this.stopDrag);

      console.log('Завершение перетаскивания');
    },
    onPopoverClick(event) {
      if (this.hasDragged) {
        // Если произошло перетаскивание, предотвращаем действие клика
        event.preventDefault();
        event.stopPropagation();
        this.isPopoverOpen = false; // Убедимся, что Popover закрыт
        this.hasDragged = false; // Сбрасываем флаг
        console.log('Клик предотвращён из-за перетаскивания');
      } else {
        // Если клика не было после перетаскивания, переключаем состояние Popover
        this.isPopoverOpen = !this.isPopoverOpen;
        console.log('Popover переключен');
      }
    },
    handleContainerClick(event) {
      console.log(event);
      // Дополнительная обработка клика на контейнере, если необходимо
      // В данном случае, ничего не делаем
    },
  },
};
</script>

<style scoped>
.fixed {
  /* Изменение курсора для обозначения возможности перетаскивания */
  cursor: grab;
  /* Предотвращение выделения текста и других действий при перетаскивании */
  user-select: none;
  touch-action: none;
}

.fixed:active {
  cursor: grabbing;
}
</style>
