<template>
  <div class="pt-4 px-4 pb-20">
    <h3 class="text-3xl font-bold mb-5">{{ $t('referral_program') }}</h3>

    <div class="flex flex-col items-center">
      <div class="w-full grid gap-3 grid-cols-2">
        <div class="rounded-xl flex flex-col w-full items-center py-2" :class="[$theme.cardBg]">
          <div class="text-xs">{{ $t('available') }}</div>
          <div class="text-3xl font-bold">{{ availableForWithdrawal }} $</div>
          <div class="w-full px-2 mt-2">
            <button
                @click="onOpenCreateWithdraw"
                class="w-full py-1.5 rounded-lg flex items-center justify-center"
                :class="[$theme.outlineBtnBg, $theme.outlineBtnTextColor]"
            >
              <ArrowUpRightIcon class="w-5 h-5 mr-2" />
              <div class="text-sm font-semibold">
                {{ $t('withdraw') }}
              </div>
            </button>
          </div>
        </div>
        <div class="rounded-xl flex flex-col w-full items-center py-2" :class="[$theme.cardBg]">
          <div class="text-xs opacity-50">{{ $t('total_earned') }}</div>
          <div class="text-3xl font-bold opacity-50">{{ totalReferralPayout }} $</div>
          <div class="w-full px-2 mt-2">
            <a
                :href="shareLink"
                class="w-full py-1.5 rounded-lg flex items-center justify-center"
                :class="[$theme.outlineBtnBg, $theme.outlineBtnTextColor]"
            >
              <UserPlusIcon class="w-5 h-5 mr-2" />
              <div class="text-sm font-semibold">
                {{ $t('invite_friend') }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div :class="[$theme.cardBg, 'rounded-xl mt-5 px-3 py-2']">
      <div class="text-xs opacity-60 mb-1">Your referral code:</div>
      <div class="border border-dashed px-2 py-1 rounded-lg flex items-center justify-between">
        <input ref="ref_code" class="bottom-0 p-0 border-transparent focus:outline-none rounded-lg bg-transparent" :value="$user.referral_code" readonly>
        <button @click="copyToClipboard" :class="[$theme.navActiveTextColor2]">{{ $t('copy') }}</button>
      </div>
    </div>

    <a :href="shareLink" class="mb-5 mt-5 flex w-full rounded-xl px-3 py-2 bg-gradient-to-br from-blue-500 via-purple-600 to-pink-700">
      <div class="text-white text-sm">
        {{ $t('earn_up_to_25') }}
      </div>
    </a>

    <div class="mb-3 block">
      <nav class="flex space-x-4" aria-label="Tabs">
        <a
            v-for="tab in tabs"
            :key="tab.key"
            @click="onChangeTab(tab.key)"
            href="javascript:void(0)"
            :class="[activeTab === tab.key ? `${$theme.activeTabBg} ${$theme.activeTabTextColor}` : $theme.tabTextColor, 'rounded-lg px-3 py-2 text-sm font-medium']"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>

    <template v-if="loading">
      <div class="mt-5 rounded-xl" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <template v-if="activeTab === 'refs'">
        <template v-if="referrers.data.length > 0">
          <div v-for="referrer in referrers.data" :key="referrer.id" class="rounded-xl mb-3 flex items-center justify-between px-2 py-2" :class="[$theme.cardBg]">
            <div class="flex items-center">
              <div>
                <div class="w-10 h-10 rounded-full flex items-center justify-center" :class="[$theme.avatarBg]">
                  {{ referrer.user ? referrer.user.name[0] : '?' }}
                </div>
              </div>
              <div class="pl-3">
                {{ referrer.user ? referrer.user.name : '' }}
                <div class="text-sm" :class="[referrer.status ? $theme.successPnlColor : 'text-yellow-500']">
                  {{ referrer.status ? $t('done') : $t('ref_in_processing') }}
                </div>
              </div>
            </div>
            <div class="flex items-center flex-col">
              <div>
                <template v-if="referrer.status">
                  <span :class="[$theme.successPnlColor]">+{{ referrer.completed_amount }} $</span>
                </template>
              </div>
              <div></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mt-5 rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
            <p class="text-center">{{ $t('you_have_no_referrals') }}</p>
          </div>
        </template>
      </template>

      <template v-if="activeTab === 'history'">
        <div class="rounded-xl bg-yellow-100 p-4 mb-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5 text-yellow-600" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-yellow-700">
                {{ $t('processing_request_withdraw') }}
              </p>
            </div>
          </div>
        </div>

        <template v-if="withdrawalRequests && withdrawalRequests.data.length > 0">
          <div
            v-for="withdrawalRequest in withdrawalRequests.data"
            :key="withdrawalRequest.id"
            class="rounded-xl px-3 py-2 flex items-center justify-between mb-3"
            :class="[$theme.cardBg]"
          >
            <div class="flex items-center">
              <div>
                <img src="/img/assets/USDT.png" class="w-10 h-10 rounded-full" />
              </div>
              <div class="pl-2">
                <div class="">{{ $t('at_withdraw') }}: {{ withdrawalRequest.currency }} {{ withdrawalRequest.network }}</div>
                <div class="text-sm opacity-50">{{ withdrawalRequest.created_at_format }}</div>
              </div>
            </div>
            <div class="flex flex-col items-end">
              <div>{{ withdrawalRequest.amount }}</div>
              <div class="text-sm">
                <template v-if="withdrawalRequest.status === 'pending'">
                  <span class="text-yellow-500 text-sm">
                    {{ $t('pending') }}
                  </span>
                </template>
                <template v-if="withdrawalRequest.status === 'approved'">
                  <span class="text-sm" :class="[$theme.successPnlColor]">
                    {{ $t('approved') }}
                  </span>
                </template>
                <template v-if="withdrawalRequest.status === 'rejected'">
                  <span class="text-sm" :class="[$theme.dangerPnlColor]">
                    {{ $t('rejected') }}
                  </span>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mt-5 rounded-xl overflow-hidden px-4 py-4" :class="[$theme.cardBg]">
            <p class="text-center">{{ $t('no_withdraw') }}</p>
          </div>
        </template>
      </template>
    </template>
  </div>

  <TransitionRoot as="template" :show="isOpenCreateWithdraw">
    <Dialog class="relative z-50" @close="isOpenCreateWithdraw = false">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div class="fixed inset-0 z-50 flex items-end justify-center">
        <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300"
            enter-from="translate-y-full opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="transform transition ease-in-out duration-300"
            leave-from="translate-y-0 opacity-100"
            leave-to="translate-y-full opacity-0"
        >
          <DialogPanel class="w-full bg-white rounded-t-2xl px-4 pt-5 pb-4 text-left shadow-xl sm:max-w-lg sm:w-full sm:p-6">
            <template v-if="wallets.length">
              <div>
                <div class="mb-3">
                  <DialogTitle as="h3" class="text-sm leading-6 text-gray-900">
                    {{ $t('available_you') }}
                  </DialogTitle>
                  <div class="text-3xl font-semibold" :class="[availableForWithdrawal > 0 ? '' : 'opacity-50 pointer-events-none']">
                    {{ availableForWithdrawal }} $
                  </div>
                </div>

                <fieldset aria-label="Address">
                  <div class="text-sm mb-2">{{ $t('select_wallet_address') }}</div>
                  <RadioGroup v-model="createWithdrawalRequestForm.wallet_id" class="space-y-2">
                    <RadioGroupOption
                        as="template"
                        v-for="wallet in wallets"
                        :key="wallet.id"
                        :value="wallet.id"
                        :aria-label="wallet.label"
                        :aria-description="`${wallet.id}, ${wallet.label}`" v-slot="{ active, checked }"
                    >
                      <div :class="[active ? 'border-blue-600 ring-2 ring-blue-600' : 'border-gray-300', 'relative block cursor-pointer rounded-xl border bg-white px-3 py-2 shadow-sm focus:outline-none sm:flex sm:justify-between']">
                          <span class="flex items-center">
                            <span class="flex flex-col text-sm">
                              <span class="font-medium text-gray-900">{{ wallet.label }}</span>
                              <span class="text-gray-500">
                                <span class="block">{{ wallet.address }}</span>
                              </span>
                            </span>
                          </span>
                        <CheckCircleIcon :class="[wallet.id !== createWithdrawalRequestForm.wallet_id ? 'invisible' : '', 'h-5 w-5 text-blue-600 absolute top-2 right-2']" aria-hidden="true" />
                        <span :class="[active ? 'border' : 'border-2', checked ? 'border-blue-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-xl']" aria-hidden="true" />
                      </div>
                    </RadioGroupOption>
                  </RadioGroup>
                </fieldset>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                    @click="onCreateWithdrawalRequest"
                    type="button"
                    class="inline-flex w-full justify-center rounded-xl bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    :class="[
                      availableForWithdrawal > 0 ? '' : 'opacity-50 pointer-events-none',
                      isFormProcessing ? 'pointer-events-none opacity-60' : ''
                    ]"
                >
                  {{ $t('withdraw') }}
                </button>
              </div>
            </template>
            <template v-else>
              <div>
                <div>
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    {{ $t('add_wallet') }}
                  </DialogTitle>

                  <div class="mt-3">
                    <label for="wallet_label" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('name') }}</label>
                    <div class="mt-2">
                      <input v-model="createWalletForm.label" type="text" id="wallet_label" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="wallet_address" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('usdt_address') }}</label>
                    <div class="mt-2">
                      <input v-model="createWalletForm.address" type="text" id="wallet_address" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                    </div>
                  </div>

                  <template v-if="formValidateErrors.length">
                    <div class="mt-3 rounded-xl bg-red-50 p-2">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">{{ $t('errors') }}</h3>
                          <div class="mt-2 text-sm text-red-700">
                            <ul role="list" class="list-none space-y-1">
                              <li v-for="(formValidateError, formValidateErrorIndex) in formValidateErrors" :key="formValidateErrorIndex">
                                {{ formValidateError }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                    @click="onCreateUserWallet"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    :class="[isFormProcessing ? 'pointer-events-none opacity-60' : '']"
                >
                  {{ $t('add') }}
                </button>
              </div>
            </template>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue';
import {UserPlusIcon, ArrowUpRightIcon} from "@heroicons/vue/24/outline";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupOption
} from '@headlessui/vue'
import {CheckCircleIcon, InformationCircleIcon, XCircleIcon} from '@heroicons/vue/20/solid'

export default {
  components: {
    InformationCircleIcon,
    CheckCircleIcon,
    LoadingBlock,
    UserPlusIcon,
    ArrowUpRightIcon,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XCircleIcon,
    RadioGroup,
    RadioGroupOption
  },

  computed: {
    botUrl() {
      return this.$settings.bot_url;
    },

    totalReferralPayout() {
      return this.$user.total_referral_payout;
    },

    availableForWithdrawal() {
      return this.$user.available_for_withdrawal;
    },

    shareLink() {
      if (!this.$user) {
        return '';
      }

      return `https://t.me/share/url?url=${encodeURIComponent(`${this.botUrl}?start=${this.$user.referral_code}`)}&text=${encodeURIComponent(this.$t('invite_message'))}`;
    },
    copyLinkNoEncode() {
      if (!this.$user) {
        return '';
      }

      return `${`${this.botUrl}?start=${this.$user.referral_code}`}&text=${this.$t('invite_message')}`;
    },
  },

  methods: {
    copyToClipboard() {
      const copyText = this.copyLinkNoEncode;
      navigator.clipboard.writeText(copyText)
          .then(() => {
            alert(copyText);
          })
          .catch((error) => {
            console.error('Ошибка копирования ссылки:', error);
            alert('Error copy link');
          });
    },

    loadUser() {
      this.$api.get('/user').then(result => {
        this.$user = result.data.data;
      })
    },

    onLoadReferrers() {
      this.loading = true;

      this.$api.get('/referrers').then(result => {
        console.log(result.data);
        this.referrers = result.data;
        this.loading = false;
      });
    },

    onLoadUserWallets() {
      this.$api.get('/user/wallets').then(result => {
        this.wallets = result.data.data;
      });
    },

    onLoadWithdrawalRequest() {
      this.loading = true;

      this.$api.get('/withdrawals').then(result => {
        console.log(result.data);
        this.withdrawalRequests = result.data;
        this.loading = false;
      });
    },

    onCreateWithdrawalRequest() {
      this.isFormProcessing = true;

      this.$api.post('/withdrawals', {
        wallet_id: this.createWithdrawalRequestForm.wallet_id,
        amount: this.availableForWithdrawal,
      }).then(result => {
        console.log(result);

        this.loadUser();

        this.isFormProcessing = true;
        this.isOpenCreateWithdraw = false;
        this.activeTab = 'history';

        this.onLoadWithdrawalRequest();
      })
    },

    onChangeTab(key) {
      console.log('key', key);

      if (key === 'refs') {
        console.log('load refs', key);
        this.onLoadReferrers();
      }

      if (key === 'history') {
        console.log('load history', key);
        this.onLoadWithdrawalRequest();
      }

      this.activeTab = key;
    },

    onOpenCreateWithdraw() {
      this.isOpenCreateWithdraw = true;

      if (this.wallets.length > 0) {
        this.createWithdrawalRequestForm.wallet_id = this.wallets[0].id;
      }
    },

    onCreateUserWallet() {
      this.validateWalletForm();

      if (this.formValidateErrors.length > 0) {
        return;
      }

      this.isFormProcessing = true;

      this.$api.post('/user/wallets', this.createWalletForm)
          .then(response => {
            console.log(response);

            this.onLoadUserWallets();
            // this.isOpenCreateWithdraw = false;
          })
          .catch(error => {
            console.log('Ошибка при добавлении кошелька', error);
          });
    },

    validateWalletForm() {
      this.formValidateErrors = [];

      if (!this.createWalletForm.label) {
        this.formValidateErrors.push(this.$t('empty_name_wallet'));
      }

      if (!this.createWalletForm.address) {
        this.formValidateErrors.push(this.$t('empty_address_wallet'));
      }

      const addressRegex = /^[a-zA-Z0-9]{34}$/;
      if (!addressRegex.test(this.createWalletForm.address)) {
        this.formValidateErrors.push(this.$t('invalid_address_wallet'));
      }
    },
  },

  data() {
    return {
      loading: true,
      referrers: null,
      withdrawalRequests: null,
      wallets: [],

      activeTab: 'refs',
      tabs:  [
        { name: this.$t('referrals'), key: 'refs',},
        { name: this.$t('history_withdraw'), key: 'history',},
      ],

      isOpenCreateWithdraw: false,

      isFormProcessing: false,
      formValidateErrors: [],
      createWalletForm: {
        label: '',
        address: '',
        currency: 'USDT',
        network: 'TRC20',
      },

      createWithdrawalRequestForm: {
        wallet_id: null,
        amount: 0,
      }
    }
  },

  mounted() {
    this.onLoadReferrers();
    this.onLoadUserWallets();
  }
}
</script>